import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { Text } from 'components/Typography/Text'
import { ExternalLinkModal } from 'components/Utility/ExternalLinkModal'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import { envVariables } from 'environment'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { ampli } from 'src/ampli'
import { Sizing } from 'styles'
import { useAppSelector } from 'store/hooks'
import { linkParams } from 'store/authSlice'

export const Calculator_01_Disclaimer = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  const { privacyUrl } = envVariables

  const [externalVisible, setExternalVisible] = useState(false)

  const isWeb = platformIsWeb()

  const next = () => {
    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'start',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })
    navigation.navigate(nextScreen)
  }

  const openWebView = () => {
    setExternalVisible(true)
  }

  const closeWebView = () => {
    setExternalVisible(false)
  }
  
  const openInBrowser = () => { 
      window.open(privacyUrl)
  }

  const features: FeatureBoxRow[] = [
    { label: `Capture all of your retirement planning` },
    { label: `Get accurate predictons and suggestions` },
  ]

  return (
    <ProcessScreen
      buttonTitle={`I understand`}
      buttonAction={next}
      enableButton={true}
      allowTextButton={true}
      textButtonTitle={'Privacy Policy'}
      textButtonAction={isWeb ? openInBrowser : openWebView}
      headline={`A few quick notes before we start...`}
      subHeadingNumberOfLines={3}
      footerInfo={<FeatureBox data={features} />}
    >
      <View style={{ maxWidth: scaleNormalizer(600) }}>
        <BulletItem style={{ textAlign: 'left' }}>{`This calculator is for illustration purposes only and provides a rough estimate of your achievable retirement age.`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Pension switching may not be right for everyone.`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Jarvis is a trading name of Pension Jar Limited.`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Pension Jar Limited is an appointed representative of P1 Investment Services Limited, which is authorised and regulated by the Financial Conduct Authority under firm reference number 752005.`}</BulletItem>
      </View>
      {externalVisible && 
        <ExternalLinkModal
          url={privacyUrl}
          onDismiss={closeWebView}
        />
      }
    </ProcessScreen>
  )
}
