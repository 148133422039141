import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import * as WebBrowser from 'expo-web-browser'
import { getLastUserEmail, userIsLoggingIn } from 'lib/authHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ampli } from 'src/ampli'
import { AppContext, linkParams, loginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { usePasswordlessStartMutation } from 'store/passwordlessSlice'
import { updateWorkingRegistration } from 'store/tempDataSlice'

WebBrowser.maybeCompleteAuthSession()

export const Authentication_03_Initiation = ({ route, navigation }) => {
  const { nextScreen } = route?.params || {}
  const dispatch = useDispatch()

  const appContext = useSelector((state: any) => state.auth.appContext)

  const loginConfig = useAppSelector(loginParams)
  const { emailAddress, authType, fromCalculator } = loginConfig || {}

  const authUser = useSelector((state: any) => state.auth.user)

  //State
  const [doPasswordlessStart, { error: passwordlessStartError }] = usePasswordlessStartMutation()

  //Function to initiate passwordless login
  const passwordlessLogin = (attributes) => {
    Logger.info(`Initiating passwordless login for : ${attributes.email}`)
    doPasswordlessStart({ email: attributes.email })
    dispatch(updateWorkingRegistration({ email: attributes.email }))

    if (fromCalculator) {
      //PA-2201 - Removed
      // ampli.calculatorUsage({
      //   processStep: 'send_auth_email',
      //   affiliateCode,
      //   affiliateRef,
      // })
    }

    navigation.navigate(nextScreen)
  }
  
  //Setup email login form
  const formObj = useForm<{ email: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: emailAddress || '',
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid } } = formObj

  const email = watch('email')

  //On load, look for lastUserEmail
  useEffect(()=>{
    const lookForLastUserEmail = async () => {
      //Look for token in SecureStore/AsyncStorage
      const lastUserEmail = await getLastUserEmail()
      if (!email && lastUserEmail) {
        setValue('email', lastUserEmail, { shouldValidate: true, shouldDirty: true })
      } else {
        Logger.debug(`No last user email address.`)
      }
    }
    lookForLastUserEmail()
  },[])

  const isLogin = authType === AUTHTYPE.LOGIN ? true : false
  const loggingIn = userIsLoggingIn(authUser)

  return (
    <ProcessScreen
      isLoading={loggingIn}
      buttonTitle={'Send magic link'}
      buttonAction={handleSubmit(passwordlessLogin)}
      enableButton={isValid}
      headline={isLogin
        ? `Enter your registered email`
        : `Enter your email address`
      }
      subHeading={
        appContext === AppContext.ADMIN ? `This should be your @pensionjar.com email`
        : appContext === AppContext.EMPLOYER ? `This should be your work/organisation email`
          : isLogin
            ? `This should be the email associated with your Jarvis account`
            : `We'll send you a magic link to get started`
        }
      >
      <ManagedTextInput
        name={'email'}
        placeholder={
          appContext === AppContext.ADMIN ? `Enter @pensionjar.com email`
          : appContext === AppContext.EMPLOYER ? `Enter work/organisation email`
          : 'Enter email'
        }
        autoFocus={!emailAddress}
        formObj={formObj}
        keyboardType={'email-address'}
        autoCapitalize={'none'}
        submitHandler={handleSubmit(passwordlessLogin)}
        blurOnSubmit={true}
        rules={{
          required: true, 
          pattern: {
            value: /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Invalid email address"
          }
        }} />
          {
            authUser.error
              ? <Paragraph>{authUser.error}</Paragraph>
              : <></>
          }
    </ProcessScreen>
  )
}