import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { batch } from 'react-redux'
import { ampli } from 'src/ampli'
import { linkParams, setLoginParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_70_ReportEmail2 = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId } = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const [showDialog, setShowDialog] = useState(false)

  //Setup form
  const formObj = useForm<{ email: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: workingCalculatorRequestData?.email
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    batch(() => {
      dispatch(updateWorkingCalculatorRequest({
        sendReport: true,
        ...attributes
      }))
      dispatch(setLoginParams({
        emailAddress: attributes?.email,
      }))
    })

    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'email2',
    //   outcome: 'provided',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })

    navigation.navigate(nextScreen)
  }

  const skip = () => {
    setShowDialog(false)

    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'email2',
    //   outcome: 'refused',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })

    navigation.navigate(nextScreen)
  }

  const email = watch('email')

  return (
    <ProcessScreen
      buttonTitle={'Continue'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      allowTextButton={!email}
      textButtonTitle={`I'm still not interested`}
      textButtonAction={() => setShowDialog(true)}
      headline={`Can we email you these results?`}
      subHeading={`We'll send you a summary from the calculator, plus a link to download Jarvis`}
    >
      <ManagedTextInput
        name={'email'}
        placeholder={'Enter your email address'}
        autoFocus={false}
        formObj={formObj}
        keyboardType={'email-address'}
        autoCapitalize={'none'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        rules={{
          required: true,
          pattern: {
            value: /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Invalid email address"
          }
        }}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`Last chance to get your results sent to you!`}
        cancelLabel={'Go back'}
        confirmLabel={'Continue without email'}
        onConfirm={skip}
      />
    </ProcessScreen>
  )
}
